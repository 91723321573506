/* eslint-disable no-restricted-syntax */
import { ClientTemplate, Component } from '@/components/ClientTemplate';
import {
  Pages,
  AllowData,
  AllowRequestPayload,
  AllowPageTemplate,
  NameCheck,
  TemplateForceCommunicationCheck,
  TemplateNameCheck,
  TemplateForceIdentifierCheck,
  TemplateForceNameCheck,
} from '@/types';
import { isForm } from '@/helpers/dom';

interface ValidationResult {
  [type: string]: {
    [type: string]: boolean | undefined;
  } | undefined;
}

enum ValidationTypes {
    Communication = 'communication',
    Name = 'name',
    Identifier = 'identifier',
}

interface ForceChecks {
  [type: string]: {
    index: number;
  }[];
}

const NAME_PROPERTY_ALLOW_TEMPLATE = 'naming';
const NAME_PROPERTY_PDS_API = 'name';

@Component
export default class ClientAllow extends ClientTemplate<Pages.Allow> {
  checks: AllowData | null = null;

  validationResult: ValidationResult = {};

  created() {
    const page = this.store.getPage(Pages.Allow);
    if (page) {
      this.checks = page;
    } else {
      this.router.goTo(Pages.Login);
    }
  }

  get optionalNames(): TemplateNameCheck[] {
    const output = this.checks?.optional_name_checks ?? [];
    return this.mapNames(output);
  }

  get forceCommunications(): TemplateForceCommunicationCheck[] {
    const checks = this.checks?.force_communication_checks ?? {};
    return Object.keys(checks).map((type) => ({
      type,
      error: this.hasError(ValidationTypes.Communication, type),
      communications: checks[type],
    }));
  }

  get forceIdentifiers(): TemplateForceIdentifierCheck[] {
    const checks = this.checks?.force_identifier_checks ?? {};
    return Object.keys(checks).map((type) => ({
      type,
      error: this.hasError(ValidationTypes.Identifier, type),
      identifiers: checks[type],
    }));
  }

  get forceNames(): TemplateForceNameCheck[] {
    const checks = this.checks?.force_name_checks ?? {};
    return Object.keys(checks).map((type) => ({
      type,
      error: this.hasError(ValidationTypes.Name, type),
      names: this.mapNames(checks[type]),
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  private mapNames(names: NameCheck[]) {
    return names.map((item) => ({
      index: item.index,
      check: item.check,
      type: item.type,
      firstName: item.first_name,
      lastName: item.last_name,
      middleName: item.middle_name,
    }));
  }

  // eslint-disable-next-line class-methods-use-this
  private mapFormToPayload(form: HTMLFormElement) {
    const output: Record<string, number[]> = {};
    const formData = new FormData(form);
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of formData.entries()) {
      let currentKey = key;
      if (key === NAME_PROPERTY_ALLOW_TEMPLATE) {
        currentKey = NAME_PROPERTY_PDS_API;
      }
      if (currentKey in output === false) {
        output[currentKey] = [];
      }
      output[currentKey].push(Number(value));
    }
    return output as unknown as AllowRequestPayload;
  }

  private hasError(key: string, type: string) {
    const validation = this.validationResult[key];
    if (validation) {
      if (validation[type] === undefined) return false;
      return Boolean(validation[type]) === false;
    }
    return false;
  }

  async sendPermissions(form: Element) {
    if (isForm(form)) {
      const permissions = this.mapFormToPayload(form);
      const valid = this.validatePermissions(permissions);
      if (valid === false) return;
      const result = await this.pds.allow(permissions);
      this.router.changePage(result);
    }
  }

  private validatePermissions(permissions: AllowRequestPayload): boolean {
    if (this.checks == null) return false;
    this.validateForceChecks(
      ValidationTypes.Communication,
      this.checks.force_communication_checks,
      permissions.communication,
    );
    this.validateForceChecks(
      ValidationTypes.Identifier,
      this.checks.force_identifier_checks,
      permissions.identifier,
    );
    this.validateForceChecks(
      ValidationTypes.Name,
      this.checks.force_name_checks,
      permissions.name,
    );
    for (const name of Object.keys(this.validationResult)) {
      const validation = this.validationResult[name];
      if (validation) {
        for (const type of Object.keys(validation)) {
          if (validation[type] === false) {
            return false;
          }
        }
      }
    }
    return true;
  }

  private validateForceChecks(
    key: string | number,
    forceChecks: ForceChecks = {},
    checks: number[] = [],
  ) {
    const checkedIndexes = new Set(checks);
    if (this.validationResult[key] === undefined) {
      this.$set(this.validationResult, key, {});
    }
    const validation = this.validationResult[key];
    if (validation) {
      for (const type of Object.keys(forceChecks)) {
        this.$set(validation, type, false);
        for (const item of forceChecks[type]) {
          if (checkedIndexes.has(item.index)) {
            this.$set(validation, type, true);
            break;
          }
        }
      }
    }
  }

  build() {
    const data: AllowPageTemplate = {
      allowLink: this.actions.create((element) => {
        this.sendPermissions(element);
      }),
      communicationsNotEmpty:
        Boolean(this.checks?.optional_communication_checks) || this.forceCommunications.length > 0,
      identifiersNotEmpty:
        Boolean(this.checks?.optional_identifier_checks) || this.forceIdentifiers.length > 0,
      namesNotEmpty: this.optionalNames.length > 0 || this.forceNames.length > 0,
      optionalCommunications: this.checks?.optional_communication_checks ?? [],
      optionalIdentifiers: this.checks?.optional_identifier_checks ?? [],
      optionalNames: this.optionalNames,
      forceCommunications: this.forceCommunications,
      forceIdentifiers: this.forceIdentifiers,
      forceNames: this.forceNames,
    };
    return [Pages.Allow, data] as const;
  }
}
